@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h3 {
    @apply text-black font-medium text-2xl;
  }
}


@layer components {
  .scroll-bar {
    @apply 
      [&::-webkit-scrollbar]:w-2 
      [&::-webkit-scrollbar]:h-2 
      [&::-webkit-scrollbar-track]:bg-app-gray 
      [&::-webkit-scrollbar-thumb]:bg-app-sideBar;

    /* Adding border-radius to the scrollbar track and thumb */
    @apply [&::-webkit-scrollbar-track]:rounded-full;
    @apply [&::-webkit-scrollbar-thumb]:rounded-full;
  }
}
